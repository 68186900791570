import styled from "@emotion/styled";
import React, {useContext, useEffect, useState} from "react";
import {ScrollState, scrollStateZero} from "../ScrollState";
import {useGetBookQuery, useGetChapterQuery} from "../store/objectsApi";
import {ChapterText} from "./ChapterText";
import {ChapterHeader} from "./ChapterHeader";
import {Link} from "react-router-dom";
import "../App.css";
import {StyledLink} from "./Link";

interface ChapterViewProps {
    bookId: string;
}

interface ChapterItemProps {
    bookId: string;
    chapterIndex: number;
    chapterId: string;
}

const ExternalBorder = styled('div')`
  border: #303030 2px solid;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  background-color: #202020;
`;

export const ChapterItem = (props: ChapterItemProps) => {
    const chapter = useGetChapterQuery(props.chapterId);
    if (chapter.isLoading) {
        return <div className="chapter-view-status">Loading</div>
    }
    if (chapter.isError || !chapter.isSuccess) {
        console.log("chapter loading error", chapter.error)
        return <div className="chapter-view-status">Error</div>
    }
    return <ExternalBorder>
        <StyledLink to={`/book/${props.bookId}/${props.chapterId}`}>
            {props.chapterIndex}. {chapter.data.name}
        </StyledLink>
    </ExternalBorder>
}

const BookViewHeader = styled.div`
  position: fixed;
  top: 0;
  background-color: #202020;
  padding: 5px;
  width: 100%;
  height: 22px;
`;

const BookViewBody = styled.div`
  padding: 5px;
  margin-top: 27px;
  height: 100vh;
  overflow: scroll;

  /* hide scrollbars */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const BookView = (props: ChapterViewProps) => {
    const book = useGetBookQuery(props.bookId)
    if (book.isLoading) {
        return <div className="chapter-view-status">Loading</div>;
    }
    if (book.isError || !book.isSuccess) {
        console.log("book loading error", book.error);
        return <div className="chapter-view-status">Error</div>;
    }

    return (
        <div>
        <BookViewHeader>
            <StyledLink to="/library">Back</StyledLink> <span>{book.data.metadata?.author?.name} - {book.data.metadata?.title}</span>
        </BookViewHeader>
        <BookViewBody>
            {book.data.chapterIds.map((chapterId, index) =>
                <ChapterItem key={chapterId} bookId={props.bookId} chapterIndex={index} chapterId={chapterId} />)
            }
        </BookViewBody>
        </div>
    );
}
