import {Book} from "./Book";

export interface ScrollState {
    source: string,
    top: number,
    client: number,
    height: number,
    sectionTop: number,
    sectionBottom: number,
}

export const scrollStateZero: ScrollState = {
    source: 'init',
    top: 0,
    client: 0,
    height: 0,
    sectionTop: 0,
    sectionBottom: 0,
}

export interface ReadingState {
    book: Book,
    chapter: number,
    scroll: ScrollState,
}