import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {com} from "../format/book";
import BookObject = com.naphaso.bookreader.BookObject;
import ChapterObject = com.naphaso.bookreader.ChapterObject;
import LibraryDirectory = com.naphaso.bookreader.LibraryDirectory;
import ILibraryDirectory = com.naphaso.bookreader.ILibraryDirectory;
import IBookObject = com.naphaso.bookreader.IBookObject;
import IChapterObject = com.naphaso.bookreader.IChapterObject;

export const mainApi = createApi({
    reducerPath: 'mainApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
    }),
    endpoints: (builder) => ({
        getLibraryDirectory: builder.query<LibraryDirectory, string>({
            query: (dir) => ({
                url: `library/${dir}`,
                headers: {Accept: 'application/x-protobuf'},
                responseHandler: resp => resp.arrayBuffer()
                    .then(buffer =>
                        new Promise<ILibraryDirectory>(accept =>
                            accept(LibraryDirectory.decode(new Uint8Array(buffer)).toJSON())))
            }),
        }),
    }),
})

export const objectsApi = createApi({
    reducerPath: 'objectsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_OBJECTS_URL,
    }),
    endpoints: (builder) => ({
        getBook: builder.query<BookObject, string>({
            query: (id) => ({
                url: `book/${id}`,
                responseHandler: resp => resp.arrayBuffer()
                    .then(buffer =>
                        new Promise<IBookObject>(accept =>
                            accept(BookObject.decode(new Uint8Array(buffer)).toJSON())))
            }),
        }),
        getChapter: builder.query<ChapterObject, string>({
            query: (id) => ({
                url: `chapter/${id}`,
                responseHandler: resp => resp.arrayBuffer()
                    .then(buffer =>
                        new Promise<IChapterObject>(accept =>
                            accept(ChapterObject.decode(new Uint8Array(buffer)).toJSON())))
            }),
        }),
    }),
})

export const { useGetLibraryDirectoryQuery, useLazyGetLibraryDirectoryQuery } = mainApi
export const {
    useGetBookQuery,
    useLazyGetBookQuery,
    useGetChapterQuery,
    useLazyGetChapterQuery,
} = objectsApi
