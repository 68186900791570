import styled from "@emotion/styled";
import {Link} from "react-router-dom";

export const StyledLink = styled(Link)`
  &:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }

  &:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }
`;