import {useParams} from "react-router-dom";
import {BookView} from "../view/BookView";

import "../App.css";

export const BookScreen = () => {
    const { bookId } = useParams();
    if (!bookId) {
        return <div className="chapter-view-status">error</div>
    }
    return <BookView bookId={bookId} />
}
