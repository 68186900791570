import styled from "@emotion/styled";
import {useGetBookQuery, useGetLibraryDirectoryQuery} from "../store/objectsApi";
import {com} from "../format/book";
import ILibraryBookEntry = com.naphaso.bookreader.ILibraryBookEntry;

import "../App.css"
import IBookObject = com.naphaso.bookreader.IBookObject;
import {Link} from "react-router-dom";
import {StyledLink} from "../view/Link";

const ExternalBorder = styled('div')`
  border: #303030 2px solid;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  background-color: #202020;
`;

const BookItem = (props: {bookId: string, book: IBookObject}) => {
    return <ExternalBorder>
        name: <StyledLink to={`/book/${props.bookId}`}>{props.book.metadata?.title}</StyledLink>,
        chapters: {props.book.chapterIds?.length}
    </ExternalBorder>
}

const LibraryItem = (props: {bookEntry: ILibraryBookEntry}) => {
    if (!props.bookEntry.bookId) {
        throw new Error("invalid book entry: " + JSON.stringify(props))
    }

    const bookObject = useGetBookQuery(props.bookEntry.bookId);

    if (bookObject.isLoading) {
        return <ExternalBorder>Loading</ExternalBorder>
    }

    if (bookObject.isError || !bookObject.isSuccess) {
        console.log("book loading error", bookObject.error)
        return <ExternalBorder>Error</ExternalBorder>
    }

    return <BookItem bookId={props.bookEntry.bookId} book={bookObject.data} />
}

export const LibraryScreen = () => {
    const library = useGetLibraryDirectoryQuery("/");
    if (library.isLoading) {
        return <div>Loading</div>
    }

    if (library.isError || !library.isSuccess) {
        console.log("library load error", library.error)
        return <div className="chapter-view-status">Error</div>
    }

    return <div>
        {library.data.books.map((book, key) => <LibraryItem key={key} bookEntry={book} />)}
    </div>
}
