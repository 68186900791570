import {configureStore} from "@reduxjs/toolkit";
import counter from "./counter";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {mainApi, objectsApi} from "./objectsApi";
import {setupListeners} from "@reduxjs/toolkit/query/react";
import currentBook from "./currentBook";

export const store = configureStore({
    reducer: {
        currentBook,
        [mainApi.reducerPath]: mainApi.reducer,
        [objectsApi.reducerPath]: objectsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            mainApi.middleware,
            objectsApi.middleware,
        ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// typed hooks
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
