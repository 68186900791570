import React from "react";
import {com} from "../format/book";
import BookObject = com.naphaso.bookreader.BookObject;
import ChapterObject = com.naphaso.bookreader.ChapterObject;

interface ReadingState {
    book: BookObject
    chapter: ChapterObject
    chapterNumber: number
    nextChapterId?: string
    prevChapterId?: string
}

export const ReadingContext = React.createContext<ReadingState>({} as ReadingState);
