import {ScrollState} from "../ScrollState";
import React, {useContext} from "react";
import styled from "@emotion/styled";
import {ReadingContext} from "../store/context";
import {Link} from "react-router-dom";
import {StyledLink} from "./Link";

export interface ChapterHeaderProps {
    scrollState: ScrollState
}

const HeaderBlock = styled.div`
  position: fixed;
  top: 0;
  background-color: #202020;
  padding: 5px;
  width: 100%;
  height: 22px;
  font-family: monospace;
`;

export const ChapterHeader = (props: ChapterHeaderProps) => {
    const reading = useContext(ReadingContext);

    return (
        <HeaderBlock>
            <StyledLink to={`/book/${reading.book.digest?.hash}`}>Back</StyledLink>
            &nbsp;
            section {props.scrollState.sectionTop.toFixed(2)}-{props.scrollState.sectionBottom.toFixed(2)}
            &nbsp;
            {reading.prevChapterId && <StyledLink to={`/book/${reading.book.digest?.hash}/${reading.prevChapterId}`}>prev</StyledLink>}
            &nbsp;Chapter {reading.chapterNumber}&nbsp;
            {reading.nextChapterId && <StyledLink to={`/book/${reading.book.digest?.hash}/${reading.nextChapterId}`}>next</StyledLink>}
        </HeaderBlock>
    )
}
