import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';

import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
    Route, Link,
} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {BookScreen} from "./screen/BookScreen";
import {LibraryScreen} from "./screen/LibraryScreen"
import {ChapterScreen} from "./screen/ChapterScreen";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {path: '/', element: <LibraryScreen />},
    {path: '/login', element: <div>login page</div>},
    {path: '/book/:bookId', element: <BookScreen />}, // 1e3960710e5ba3d8a8e4c307c838cbcd9bd0aeb4
    {path: '/book/:bookId/:chapterId', element: <ChapterScreen />}, // 1e3960710e5ba3d8a8e4c307c838cbcd9bd0aeb4
    {path: '/library', element: <LibraryScreen />},
]);

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <div className="app">
            <RouterProvider router={router} />
          </div>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
