import {ScrollState} from "../ScrollState";
import {com} from "../format/book";
import BookObject = com.naphaso.bookreader.BookObject;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import ChapterObject = com.naphaso.bookreader.ChapterObject;
import {counterSlice} from "./counter";

export interface CurrentBookState {
    book?: string,
    chapter?: number,
    chapterId?: string,
}

const initialState: CurrentBookState = {}

export const currentBookSlice = createSlice({
    name: 'currentBook',
    initialState,
    reducers: {
        testAction(state, action: PayloadAction<string>) {
            return state
        }
        // openBook(state, action: PayloadAction<string>) {
        //     return {
        //         ...state,
        //         book: action.payload,
        //         chapter: 0,
        //     }
        // },
        // nextChapter(state) {
        //     if (state.chapterNumber !== undefined && state.book) {
        //         if (state.chapterNumber < state.book.chapterIds.length - 1) {
        //             state.chapterNumber += 1
        //         }
        //     }
        // },
        // prevChapter(state) {
        //     if (state.chapterNumber !== undefined && state.book) {
        //         if (state.chapterNumber > 0) {
        //             state.chapterNumber -= 1
        //         }
        //     }
        // },
    },
})

export const { testAction } = currentBookSlice.actions
export default currentBookSlice.reducer
