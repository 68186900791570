import {useParams} from "react-router-dom";
import {ChapterView} from "../view/ChapterView";

import "../App.css";

export const ChapterScreen = () => {
    const { bookId, chapterId } = useParams();
    if (!bookId || !chapterId) {
        return <div className="chapter-view-status">error</div>
    }
    return <ChapterView bookId={bookId} chapterId={chapterId} />
}
