import React, {useContext, useEffect, useState} from "react";
import {ScrollState, scrollStateZero} from "../ScrollState";
import {useGetBookQuery, useGetChapterQuery} from "../store/objectsApi";
import {ChapterText} from "./ChapterText";
import {ChapterHeader} from "./ChapterHeader";
import {ReadingContext} from "../store/context";

interface ChapterViewProps {
    bookId: string;
    chapterId: string;
}

export const ChapterView = (props: ChapterViewProps) => {
    const [offset, setOffset] = useState<ScrollState>(scrollStateZero)

    const book = useGetBookQuery(props.bookId)
    const chapter = useGetChapterQuery(props.chapterId)

    if (chapter.isFetching || chapter.isLoading || book.isLoading || book.isFetching) {
        return <div className="chapter-view-status">Loading</div>
    }
    if (chapter.isError || book.isError || !chapter.isSuccess || !book.isSuccess) {
        console.log("error", chapter.error);
        return <div className="chapter-view-status">Error</div>;
    }

    let currChapter = book.data.chapterIds.findIndex((v) => v === props.chapterId);
    let nextChapter = book.data.chapterIds[currChapter + 1];
    let prevChapter = book.data.chapterIds[currChapter - 1];

    return (
        <div className="chapter-view">
            <ReadingContext.Provider value={{
                book: book.data,
                chapter: chapter.data,
                chapterNumber: currChapter,
                nextChapterId: nextChapter,
                prevChapterId: prevChapter,
            }}>
            <ChapterHeader
                scrollState={offset}
            />
            <ChapterText updateScrollState={setOffset}/>
            </ReadingContext.Provider>
        </div>
    );
}