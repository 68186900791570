import {ScrollState, scrollStateZero} from "../ScrollState";
import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {com} from "../format/book";
import ChapterObject = com.naphaso.bookreader.ChapterObject;
import {ReadingContext} from "../store/context";

const ChapterBlock = styled('div')`
    text-indent: 10px;
    font-size: 16px;
`;

interface ChapterScreenProps {
    updateScrollState: (_: ScrollState) => void
}

export const ChapterText = (props: ChapterScreenProps) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const reading = useContext(ReadingContext);
    //const {state,dispatch} = useContext(Context);

    const updateScrollState = () => {
        if (bodyRef.current) {
            // if (state.scroll?.source === 'init' || state.scroll?.source === 'server') {
            //     // calc
            //
            //     bodyRef.current.scrollTo({top: 123})
            // }

            const current = bodyRef.current;
            const children = current.children;
            let prevHeightSum = 0;
            let sectionTop: number | undefined;
            let sectionBottom: number | undefined;
            for (let i = 0; i < children.length; i++) {
                const child = children.item(i);
                if (child == null) {
                    break
                }
                prevHeightSum += child.scrollHeight;
                if (sectionTop === undefined) {
                    if (current.scrollTop < prevHeightSum) {
                        sectionTop = i +
                            (current.scrollTop - (prevHeightSum - child.scrollHeight))
                            / child.scrollHeight;
                    }
                }
                if (sectionBottom === undefined) {
                    if (current.scrollTop + current.clientHeight < prevHeightSum) {
                        sectionBottom = i +
                            (current.scrollTop + current.clientHeight - (prevHeightSum - child.scrollHeight))
                            / child.scrollHeight;
                        break
                    }
                }
            }
            if (sectionTop === undefined) {
                sectionTop = 0;
            }
            if (sectionBottom === undefined) {
                sectionBottom = children.length;
            }

            const scrollState = {
                source: 'screen',
                top: current.scrollTop,
                client: current.clientHeight,
                height: current.scrollHeight,
                sectionTop,
                sectionBottom,
            }

            props.updateScrollState(scrollState);
            // dispatch({type: 'set_scroll', scroll: scrollState})
        }
    }

    useEffect(updateScrollState, []);

    // const [fastScrollState, setFastScrollState] = useState(0);
    // const slowScrollState = useDebounce(fastScrollState, 100);
    // useEffect(() => updateScrollState(), [slowScrollState])

    return (
        <div ref={bodyRef} className="app-body" onScroll={updateScrollState}>
            {reading.chapter.blocks?.map((block, index) =>
                <ChapterBlock key={index}>
                    {block.spans?.map((span, index) => (
                        <span key={index}>{span.text}</span>
                    ))}
                </ChapterBlock>
            )}
        </div>
    )
}