/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const com = $root.com = (() => {

    /**
     * Namespace com.
     * @exports com
     * @namespace
     */
    const com = {};

    com.naphaso = (function() {

        /**
         * Namespace naphaso.
         * @memberof com
         * @namespace
         */
        const naphaso = {};

        naphaso.bookreader = (function() {

            /**
             * Namespace bookreader.
             * @memberof com.naphaso
             * @namespace
             */
            const bookreader = {};

            bookreader.LibraryDirectory = (function() {

                /**
                 * Properties of a LibraryDirectory.
                 * @memberof com.naphaso.bookreader
                 * @interface ILibraryDirectory
                 * @property {Array.<string>|null} [directories] LibraryDirectory directories
                 * @property {Array.<com.naphaso.bookreader.ILibraryBookEntry>|null} [books] LibraryDirectory books
                 */

                /**
                 * Constructs a new LibraryDirectory.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a LibraryDirectory.
                 * @implements ILibraryDirectory
                 * @constructor
                 * @param {com.naphaso.bookreader.ILibraryDirectory=} [properties] Properties to set
                 */
                function LibraryDirectory(properties) {
                    this.directories = [];
                    this.books = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LibraryDirectory directories.
                 * @member {Array.<string>} directories
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @instance
                 */
                LibraryDirectory.prototype.directories = $util.emptyArray;

                /**
                 * LibraryDirectory books.
                 * @member {Array.<com.naphaso.bookreader.ILibraryBookEntry>} books
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @instance
                 */
                LibraryDirectory.prototype.books = $util.emptyArray;

                /**
                 * Creates a new LibraryDirectory instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {com.naphaso.bookreader.ILibraryDirectory=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.LibraryDirectory} LibraryDirectory instance
                 */
                LibraryDirectory.create = function create(properties) {
                    return new LibraryDirectory(properties);
                };

                /**
                 * Encodes the specified LibraryDirectory message. Does not implicitly {@link com.naphaso.bookreader.LibraryDirectory.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {com.naphaso.bookreader.ILibraryDirectory} message LibraryDirectory message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LibraryDirectory.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.directories != null && message.directories.length)
                        for (let i = 0; i < message.directories.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.directories[i]);
                    if (message.books != null && message.books.length)
                        for (let i = 0; i < message.books.length; ++i)
                            $root.com.naphaso.bookreader.LibraryBookEntry.encode(message.books[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified LibraryDirectory message, length delimited. Does not implicitly {@link com.naphaso.bookreader.LibraryDirectory.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {com.naphaso.bookreader.ILibraryDirectory} message LibraryDirectory message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LibraryDirectory.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LibraryDirectory message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.LibraryDirectory} LibraryDirectory
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LibraryDirectory.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.LibraryDirectory();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.directories && message.directories.length))
                                    message.directories = [];
                                message.directories.push(reader.string());
                                break;
                            }
                        case 3: {
                                if (!(message.books && message.books.length))
                                    message.books = [];
                                message.books.push($root.com.naphaso.bookreader.LibraryBookEntry.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LibraryDirectory message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.LibraryDirectory} LibraryDirectory
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LibraryDirectory.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LibraryDirectory message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LibraryDirectory.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.directories != null && message.hasOwnProperty("directories")) {
                        if (!Array.isArray(message.directories))
                            return "directories: array expected";
                        for (let i = 0; i < message.directories.length; ++i)
                            if (!$util.isString(message.directories[i]))
                                return "directories: string[] expected";
                    }
                    if (message.books != null && message.hasOwnProperty("books")) {
                        if (!Array.isArray(message.books))
                            return "books: array expected";
                        for (let i = 0; i < message.books.length; ++i) {
                            let error = $root.com.naphaso.bookreader.LibraryBookEntry.verify(message.books[i]);
                            if (error)
                                return "books." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a LibraryDirectory message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.LibraryDirectory} LibraryDirectory
                 */
                LibraryDirectory.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.LibraryDirectory)
                        return object;
                    let message = new $root.com.naphaso.bookreader.LibraryDirectory();
                    if (object.directories) {
                        if (!Array.isArray(object.directories))
                            throw TypeError(".com.naphaso.bookreader.LibraryDirectory.directories: array expected");
                        message.directories = [];
                        for (let i = 0; i < object.directories.length; ++i)
                            message.directories[i] = String(object.directories[i]);
                    }
                    if (object.books) {
                        if (!Array.isArray(object.books))
                            throw TypeError(".com.naphaso.bookreader.LibraryDirectory.books: array expected");
                        message.books = [];
                        for (let i = 0; i < object.books.length; ++i) {
                            if (typeof object.books[i] !== "object")
                                throw TypeError(".com.naphaso.bookreader.LibraryDirectory.books: object expected");
                            message.books[i] = $root.com.naphaso.bookreader.LibraryBookEntry.fromObject(object.books[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LibraryDirectory message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {com.naphaso.bookreader.LibraryDirectory} message LibraryDirectory
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LibraryDirectory.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.directories = [];
                        object.books = [];
                    }
                    if (message.directories && message.directories.length) {
                        object.directories = [];
                        for (let j = 0; j < message.directories.length; ++j)
                            object.directories[j] = message.directories[j];
                    }
                    if (message.books && message.books.length) {
                        object.books = [];
                        for (let j = 0; j < message.books.length; ++j)
                            object.books[j] = $root.com.naphaso.bookreader.LibraryBookEntry.toObject(message.books[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this LibraryDirectory to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LibraryDirectory.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LibraryDirectory
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.LibraryDirectory
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LibraryDirectory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.LibraryDirectory";
                };

                return LibraryDirectory;
            })();

            bookreader.LibraryBookEntry = (function() {

                /**
                 * Properties of a LibraryBookEntry.
                 * @memberof com.naphaso.bookreader
                 * @interface ILibraryBookEntry
                 * @property {com.naphaso.bookreader.IOrigin|null} [origin] LibraryBookEntry origin
                 * @property {com.naphaso.bookreader.IBookMetadata|null} [metadata] LibraryBookEntry metadata
                 * @property {string|null} [bookId] LibraryBookEntry bookId
                 */

                /**
                 * Constructs a new LibraryBookEntry.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a LibraryBookEntry.
                 * @implements ILibraryBookEntry
                 * @constructor
                 * @param {com.naphaso.bookreader.ILibraryBookEntry=} [properties] Properties to set
                 */
                function LibraryBookEntry(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LibraryBookEntry origin.
                 * @member {com.naphaso.bookreader.IOrigin|null|undefined} origin
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @instance
                 */
                LibraryBookEntry.prototype.origin = null;

                /**
                 * LibraryBookEntry metadata.
                 * @member {com.naphaso.bookreader.IBookMetadata|null|undefined} metadata
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @instance
                 */
                LibraryBookEntry.prototype.metadata = null;

                /**
                 * LibraryBookEntry bookId.
                 * @member {string} bookId
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @instance
                 */
                LibraryBookEntry.prototype.bookId = "";

                /**
                 * Creates a new LibraryBookEntry instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {com.naphaso.bookreader.ILibraryBookEntry=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.LibraryBookEntry} LibraryBookEntry instance
                 */
                LibraryBookEntry.create = function create(properties) {
                    return new LibraryBookEntry(properties);
                };

                /**
                 * Encodes the specified LibraryBookEntry message. Does not implicitly {@link com.naphaso.bookreader.LibraryBookEntry.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {com.naphaso.bookreader.ILibraryBookEntry} message LibraryBookEntry message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LibraryBookEntry.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.origin != null && Object.hasOwnProperty.call(message, "origin"))
                        $root.com.naphaso.bookreader.Origin.encode(message.origin, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.com.naphaso.bookreader.BookMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.bookId != null && Object.hasOwnProperty.call(message, "bookId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.bookId);
                    return writer;
                };

                /**
                 * Encodes the specified LibraryBookEntry message, length delimited. Does not implicitly {@link com.naphaso.bookreader.LibraryBookEntry.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {com.naphaso.bookreader.ILibraryBookEntry} message LibraryBookEntry message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LibraryBookEntry.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LibraryBookEntry message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.LibraryBookEntry} LibraryBookEntry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LibraryBookEntry.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.LibraryBookEntry();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.origin = $root.com.naphaso.bookreader.Origin.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.metadata = $root.com.naphaso.bookreader.BookMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.bookId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LibraryBookEntry message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.LibraryBookEntry} LibraryBookEntry
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LibraryBookEntry.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LibraryBookEntry message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LibraryBookEntry.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.origin != null && message.hasOwnProperty("origin")) {
                        let error = $root.com.naphaso.bookreader.Origin.verify(message.origin);
                        if (error)
                            return "origin." + error;
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        let error = $root.com.naphaso.bookreader.BookMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    if (message.bookId != null && message.hasOwnProperty("bookId"))
                        if (!$util.isString(message.bookId))
                            return "bookId: string expected";
                    return null;
                };

                /**
                 * Creates a LibraryBookEntry message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.LibraryBookEntry} LibraryBookEntry
                 */
                LibraryBookEntry.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.LibraryBookEntry)
                        return object;
                    let message = new $root.com.naphaso.bookreader.LibraryBookEntry();
                    if (object.origin != null) {
                        if (typeof object.origin !== "object")
                            throw TypeError(".com.naphaso.bookreader.LibraryBookEntry.origin: object expected");
                        message.origin = $root.com.naphaso.bookreader.Origin.fromObject(object.origin);
                    }
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".com.naphaso.bookreader.LibraryBookEntry.metadata: object expected");
                        message.metadata = $root.com.naphaso.bookreader.BookMetadata.fromObject(object.metadata);
                    }
                    if (object.bookId != null)
                        message.bookId = String(object.bookId);
                    return message;
                };

                /**
                 * Creates a plain object from a LibraryBookEntry message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {com.naphaso.bookreader.LibraryBookEntry} message LibraryBookEntry
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LibraryBookEntry.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.origin = null;
                        object.metadata = null;
                        object.bookId = "";
                    }
                    if (message.origin != null && message.hasOwnProperty("origin"))
                        object.origin = $root.com.naphaso.bookreader.Origin.toObject(message.origin, options);
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.com.naphaso.bookreader.BookMetadata.toObject(message.metadata, options);
                    if (message.bookId != null && message.hasOwnProperty("bookId"))
                        object.bookId = message.bookId;
                    return object;
                };

                /**
                 * Converts this LibraryBookEntry to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LibraryBookEntry.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LibraryBookEntry
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.LibraryBookEntry
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LibraryBookEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.LibraryBookEntry";
                };

                return LibraryBookEntry;
            })();

            bookreader.BookObject = (function() {

                /**
                 * Properties of a BookObject.
                 * @memberof com.naphaso.bookreader
                 * @interface IBookObject
                 * @property {com.naphaso.bookreader.IBookMetadata|null} [metadata] BookObject metadata
                 * @property {com.naphaso.bookreader.IDigest|null} [digest] BookObject digest
                 * @property {Array.<string>|null} [chapterIds] BookObject chapterIds
                 */

                /**
                 * Constructs a new BookObject.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a BookObject.
                 * @implements IBookObject
                 * @constructor
                 * @param {com.naphaso.bookreader.IBookObject=} [properties] Properties to set
                 */
                function BookObject(properties) {
                    this.chapterIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BookObject metadata.
                 * @member {com.naphaso.bookreader.IBookMetadata|null|undefined} metadata
                 * @memberof com.naphaso.bookreader.BookObject
                 * @instance
                 */
                BookObject.prototype.metadata = null;

                /**
                 * BookObject digest.
                 * @member {com.naphaso.bookreader.IDigest|null|undefined} digest
                 * @memberof com.naphaso.bookreader.BookObject
                 * @instance
                 */
                BookObject.prototype.digest = null;

                /**
                 * BookObject chapterIds.
                 * @member {Array.<string>} chapterIds
                 * @memberof com.naphaso.bookreader.BookObject
                 * @instance
                 */
                BookObject.prototype.chapterIds = $util.emptyArray;

                /**
                 * Creates a new BookObject instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {com.naphaso.bookreader.IBookObject=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.BookObject} BookObject instance
                 */
                BookObject.create = function create(properties) {
                    return new BookObject(properties);
                };

                /**
                 * Encodes the specified BookObject message. Does not implicitly {@link com.naphaso.bookreader.BookObject.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {com.naphaso.bookreader.IBookObject} message BookObject message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BookObject.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                        $root.com.naphaso.bookreader.BookMetadata.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.digest != null && Object.hasOwnProperty.call(message, "digest"))
                        $root.com.naphaso.bookreader.Digest.encode(message.digest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.chapterIds != null && message.chapterIds.length)
                        for (let i = 0; i < message.chapterIds.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.chapterIds[i]);
                    return writer;
                };

                /**
                 * Encodes the specified BookObject message, length delimited. Does not implicitly {@link com.naphaso.bookreader.BookObject.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {com.naphaso.bookreader.IBookObject} message BookObject message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BookObject.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BookObject message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.BookObject} BookObject
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BookObject.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.BookObject();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.metadata = $root.com.naphaso.bookreader.BookMetadata.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.digest = $root.com.naphaso.bookreader.Digest.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.chapterIds && message.chapterIds.length))
                                    message.chapterIds = [];
                                message.chapterIds.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BookObject message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.BookObject} BookObject
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BookObject.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BookObject message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BookObject.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        let error = $root.com.naphaso.bookreader.BookMetadata.verify(message.metadata);
                        if (error)
                            return "metadata." + error;
                    }
                    if (message.digest != null && message.hasOwnProperty("digest")) {
                        let error = $root.com.naphaso.bookreader.Digest.verify(message.digest);
                        if (error)
                            return "digest." + error;
                    }
                    if (message.chapterIds != null && message.hasOwnProperty("chapterIds")) {
                        if (!Array.isArray(message.chapterIds))
                            return "chapterIds: array expected";
                        for (let i = 0; i < message.chapterIds.length; ++i)
                            if (!$util.isString(message.chapterIds[i]))
                                return "chapterIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a BookObject message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.BookObject} BookObject
                 */
                BookObject.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.BookObject)
                        return object;
                    let message = new $root.com.naphaso.bookreader.BookObject();
                    if (object.metadata != null) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".com.naphaso.bookreader.BookObject.metadata: object expected");
                        message.metadata = $root.com.naphaso.bookreader.BookMetadata.fromObject(object.metadata);
                    }
                    if (object.digest != null) {
                        if (typeof object.digest !== "object")
                            throw TypeError(".com.naphaso.bookreader.BookObject.digest: object expected");
                        message.digest = $root.com.naphaso.bookreader.Digest.fromObject(object.digest);
                    }
                    if (object.chapterIds) {
                        if (!Array.isArray(object.chapterIds))
                            throw TypeError(".com.naphaso.bookreader.BookObject.chapterIds: array expected");
                        message.chapterIds = [];
                        for (let i = 0; i < object.chapterIds.length; ++i)
                            message.chapterIds[i] = String(object.chapterIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BookObject message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {com.naphaso.bookreader.BookObject} message BookObject
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BookObject.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.chapterIds = [];
                    if (options.defaults) {
                        object.metadata = null;
                        object.digest = null;
                    }
                    if (message.metadata != null && message.hasOwnProperty("metadata"))
                        object.metadata = $root.com.naphaso.bookreader.BookMetadata.toObject(message.metadata, options);
                    if (message.digest != null && message.hasOwnProperty("digest"))
                        object.digest = $root.com.naphaso.bookreader.Digest.toObject(message.digest, options);
                    if (message.chapterIds && message.chapterIds.length) {
                        object.chapterIds = [];
                        for (let j = 0; j < message.chapterIds.length; ++j)
                            object.chapterIds[j] = message.chapterIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this BookObject to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.BookObject
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BookObject.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BookObject
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.BookObject
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BookObject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.BookObject";
                };

                return BookObject;
            })();

            bookreader.Origin = (function() {

                /**
                 * Properties of an Origin.
                 * @memberof com.naphaso.bookreader
                 * @interface IOrigin
                 * @property {com.naphaso.bookreader.IOriginFile|null} [file] Origin file
                 * @property {string|null} [url] Origin url
                 */

                /**
                 * Constructs a new Origin.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents an Origin.
                 * @implements IOrigin
                 * @constructor
                 * @param {com.naphaso.bookreader.IOrigin=} [properties] Properties to set
                 */
                function Origin(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Origin file.
                 * @member {com.naphaso.bookreader.IOriginFile|null|undefined} file
                 * @memberof com.naphaso.bookreader.Origin
                 * @instance
                 */
                Origin.prototype.file = null;

                /**
                 * Origin url.
                 * @member {string} url
                 * @memberof com.naphaso.bookreader.Origin
                 * @instance
                 */
                Origin.prototype.url = "";

                /**
                 * Creates a new Origin instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {com.naphaso.bookreader.IOrigin=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.Origin} Origin instance
                 */
                Origin.create = function create(properties) {
                    return new Origin(properties);
                };

                /**
                 * Encodes the specified Origin message. Does not implicitly {@link com.naphaso.bookreader.Origin.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {com.naphaso.bookreader.IOrigin} message Origin message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Origin.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                        $root.com.naphaso.bookreader.OriginFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                    return writer;
                };

                /**
                 * Encodes the specified Origin message, length delimited. Does not implicitly {@link com.naphaso.bookreader.Origin.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {com.naphaso.bookreader.IOrigin} message Origin message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Origin.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Origin message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.Origin} Origin
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Origin.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.Origin();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.file = $root.com.naphaso.bookreader.OriginFile.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.url = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Origin message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.Origin} Origin
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Origin.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Origin message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Origin.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.file != null && message.hasOwnProperty("file")) {
                        let error = $root.com.naphaso.bookreader.OriginFile.verify(message.file);
                        if (error)
                            return "file." + error;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };

                /**
                 * Creates an Origin message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.Origin} Origin
                 */
                Origin.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.Origin)
                        return object;
                    let message = new $root.com.naphaso.bookreader.Origin();
                    if (object.file != null) {
                        if (typeof object.file !== "object")
                            throw TypeError(".com.naphaso.bookreader.Origin.file: object expected");
                        message.file = $root.com.naphaso.bookreader.OriginFile.fromObject(object.file);
                    }
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };

                /**
                 * Creates a plain object from an Origin message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {com.naphaso.bookreader.Origin} message Origin
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Origin.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.file = null;
                        object.url = "";
                    }
                    if (message.file != null && message.hasOwnProperty("file"))
                        object.file = $root.com.naphaso.bookreader.OriginFile.toObject(message.file, options);
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };

                /**
                 * Converts this Origin to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.Origin
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Origin.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Origin
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.Origin
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Origin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.Origin";
                };

                return Origin;
            })();

            bookreader.OriginFile = (function() {

                /**
                 * Properties of an OriginFile.
                 * @memberof com.naphaso.bookreader
                 * @interface IOriginFile
                 * @property {string|null} [name] OriginFile name
                 * @property {string|null} [hash] OriginFile hash
                 * @property {number|Long|null} [size] OriginFile size
                 * @property {number|Long|null} [importTime] OriginFile importTime
                 */

                /**
                 * Constructs a new OriginFile.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents an OriginFile.
                 * @implements IOriginFile
                 * @constructor
                 * @param {com.naphaso.bookreader.IOriginFile=} [properties] Properties to set
                 */
                function OriginFile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OriginFile name.
                 * @member {string} name
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @instance
                 */
                OriginFile.prototype.name = "";

                /**
                 * OriginFile hash.
                 * @member {string} hash
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @instance
                 */
                OriginFile.prototype.hash = "";

                /**
                 * OriginFile size.
                 * @member {number|Long} size
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @instance
                 */
                OriginFile.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * OriginFile importTime.
                 * @member {number|Long} importTime
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @instance
                 */
                OriginFile.prototype.importTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new OriginFile instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {com.naphaso.bookreader.IOriginFile=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.OriginFile} OriginFile instance
                 */
                OriginFile.create = function create(properties) {
                    return new OriginFile(properties);
                };

                /**
                 * Encodes the specified OriginFile message. Does not implicitly {@link com.naphaso.bookreader.OriginFile.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {com.naphaso.bookreader.IOriginFile} message OriginFile message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OriginFile.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.hash);
                    if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.size);
                    if (message.importTime != null && Object.hasOwnProperty.call(message, "importTime"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.importTime);
                    return writer;
                };

                /**
                 * Encodes the specified OriginFile message, length delimited. Does not implicitly {@link com.naphaso.bookreader.OriginFile.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {com.naphaso.bookreader.IOriginFile} message OriginFile message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OriginFile.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an OriginFile message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.OriginFile} OriginFile
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OriginFile.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.OriginFile();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.hash = reader.string();
                                break;
                            }
                        case 3: {
                                message.size = reader.int64();
                                break;
                            }
                        case 4: {
                                message.importTime = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an OriginFile message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.OriginFile} OriginFile
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OriginFile.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an OriginFile message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OriginFile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.hash != null && message.hasOwnProperty("hash"))
                        if (!$util.isString(message.hash))
                            return "hash: string expected";
                    if (message.size != null && message.hasOwnProperty("size"))
                        if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                            return "size: integer|Long expected";
                    if (message.importTime != null && message.hasOwnProperty("importTime"))
                        if (!$util.isInteger(message.importTime) && !(message.importTime && $util.isInteger(message.importTime.low) && $util.isInteger(message.importTime.high)))
                            return "importTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an OriginFile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.OriginFile} OriginFile
                 */
                OriginFile.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.OriginFile)
                        return object;
                    let message = new $root.com.naphaso.bookreader.OriginFile();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.hash != null)
                        message.hash = String(object.hash);
                    if (object.size != null)
                        if ($util.Long)
                            (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                        else if (typeof object.size === "string")
                            message.size = parseInt(object.size, 10);
                        else if (typeof object.size === "number")
                            message.size = object.size;
                        else if (typeof object.size === "object")
                            message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
                    if (object.importTime != null)
                        if ($util.Long)
                            (message.importTime = $util.Long.fromValue(object.importTime)).unsigned = false;
                        else if (typeof object.importTime === "string")
                            message.importTime = parseInt(object.importTime, 10);
                        else if (typeof object.importTime === "number")
                            message.importTime = object.importTime;
                        else if (typeof object.importTime === "object")
                            message.importTime = new $util.LongBits(object.importTime.low >>> 0, object.importTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an OriginFile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {com.naphaso.bookreader.OriginFile} message OriginFile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OriginFile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.hash = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.size = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.importTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.importTime = options.longs === String ? "0" : 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.hash != null && message.hasOwnProperty("hash"))
                        object.hash = message.hash;
                    if (message.size != null && message.hasOwnProperty("size"))
                        if (typeof message.size === "number")
                            object.size = options.longs === String ? String(message.size) : message.size;
                        else
                            object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
                    if (message.importTime != null && message.hasOwnProperty("importTime"))
                        if (typeof message.importTime === "number")
                            object.importTime = options.longs === String ? String(message.importTime) : message.importTime;
                        else
                            object.importTime = options.longs === String ? $util.Long.prototype.toString.call(message.importTime) : options.longs === Number ? new $util.LongBits(message.importTime.low >>> 0, message.importTime.high >>> 0).toNumber() : message.importTime;
                    return object;
                };

                /**
                 * Converts this OriginFile to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OriginFile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for OriginFile
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.OriginFile
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OriginFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.OriginFile";
                };

                return OriginFile;
            })();

            bookreader.BookMetadata = (function() {

                /**
                 * Properties of a BookMetadata.
                 * @memberof com.naphaso.bookreader
                 * @interface IBookMetadata
                 * @property {string|null} [title] BookMetadata title
                 * @property {com.naphaso.bookreader.IAuthor|null} [author] BookMetadata author
                 */

                /**
                 * Constructs a new BookMetadata.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a BookMetadata.
                 * @implements IBookMetadata
                 * @constructor
                 * @param {com.naphaso.bookreader.IBookMetadata=} [properties] Properties to set
                 */
                function BookMetadata(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BookMetadata title.
                 * @member {string} title
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @instance
                 */
                BookMetadata.prototype.title = "";

                /**
                 * BookMetadata author.
                 * @member {com.naphaso.bookreader.IAuthor|null|undefined} author
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @instance
                 */
                BookMetadata.prototype.author = null;

                /**
                 * Creates a new BookMetadata instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {com.naphaso.bookreader.IBookMetadata=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.BookMetadata} BookMetadata instance
                 */
                BookMetadata.create = function create(properties) {
                    return new BookMetadata(properties);
                };

                /**
                 * Encodes the specified BookMetadata message. Does not implicitly {@link com.naphaso.bookreader.BookMetadata.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {com.naphaso.bookreader.IBookMetadata} message BookMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BookMetadata.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                    if (message.author != null && Object.hasOwnProperty.call(message, "author"))
                        $root.com.naphaso.bookreader.Author.encode(message.author, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified BookMetadata message, length delimited. Does not implicitly {@link com.naphaso.bookreader.BookMetadata.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {com.naphaso.bookreader.IBookMetadata} message BookMetadata message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BookMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BookMetadata message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.BookMetadata} BookMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BookMetadata.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.BookMetadata();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.title = reader.string();
                                break;
                            }
                        case 2: {
                                message.author = $root.com.naphaso.bookreader.Author.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BookMetadata message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.BookMetadata} BookMetadata
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BookMetadata.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BookMetadata message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BookMetadata.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.author != null && message.hasOwnProperty("author")) {
                        let error = $root.com.naphaso.bookreader.Author.verify(message.author);
                        if (error)
                            return "author." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BookMetadata message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.BookMetadata} BookMetadata
                 */
                BookMetadata.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.BookMetadata)
                        return object;
                    let message = new $root.com.naphaso.bookreader.BookMetadata();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.author != null) {
                        if (typeof object.author !== "object")
                            throw TypeError(".com.naphaso.bookreader.BookMetadata.author: object expected");
                        message.author = $root.com.naphaso.bookreader.Author.fromObject(object.author);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BookMetadata message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {com.naphaso.bookreader.BookMetadata} message BookMetadata
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BookMetadata.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.title = "";
                        object.author = null;
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.author != null && message.hasOwnProperty("author"))
                        object.author = $root.com.naphaso.bookreader.Author.toObject(message.author, options);
                    return object;
                };

                /**
                 * Converts this BookMetadata to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BookMetadata.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BookMetadata
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.BookMetadata
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BookMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.BookMetadata";
                };

                return BookMetadata;
            })();

            bookreader.Author = (function() {

                /**
                 * Properties of an Author.
                 * @memberof com.naphaso.bookreader
                 * @interface IAuthor
                 * @property {string|null} [name] Author name
                 */

                /**
                 * Constructs a new Author.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents an Author.
                 * @implements IAuthor
                 * @constructor
                 * @param {com.naphaso.bookreader.IAuthor=} [properties] Properties to set
                 */
                function Author(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Author name.
                 * @member {string} name
                 * @memberof com.naphaso.bookreader.Author
                 * @instance
                 */
                Author.prototype.name = "";

                /**
                 * Creates a new Author instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {com.naphaso.bookreader.IAuthor=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.Author} Author instance
                 */
                Author.create = function create(properties) {
                    return new Author(properties);
                };

                /**
                 * Encodes the specified Author message. Does not implicitly {@link com.naphaso.bookreader.Author.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {com.naphaso.bookreader.IAuthor} message Author message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Author.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    return writer;
                };

                /**
                 * Encodes the specified Author message, length delimited. Does not implicitly {@link com.naphaso.bookreader.Author.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {com.naphaso.bookreader.IAuthor} message Author message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Author.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Author message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.Author} Author
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Author.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.Author();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Author message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.Author} Author
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Author.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Author message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Author.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    return null;
                };

                /**
                 * Creates an Author message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.Author} Author
                 */
                Author.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.Author)
                        return object;
                    let message = new $root.com.naphaso.bookreader.Author();
                    if (object.name != null)
                        message.name = String(object.name);
                    return message;
                };

                /**
                 * Creates a plain object from an Author message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {com.naphaso.bookreader.Author} message Author
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Author.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    return object;
                };

                /**
                 * Converts this Author to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.Author
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Author.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Author
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.Author
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Author.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.Author";
                };

                return Author;
            })();

            bookreader.ChapterObject = (function() {

                /**
                 * Properties of a ChapterObject.
                 * @memberof com.naphaso.bookreader
                 * @interface IChapterObject
                 * @property {string|null} [name] ChapterObject name
                 * @property {com.naphaso.bookreader.IDigest|null} [digest] ChapterObject digest
                 * @property {Array.<com.naphaso.bookreader.IBlock>|null} [blocks] ChapterObject blocks
                 */

                /**
                 * Constructs a new ChapterObject.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a ChapterObject.
                 * @implements IChapterObject
                 * @constructor
                 * @param {com.naphaso.bookreader.IChapterObject=} [properties] Properties to set
                 */
                function ChapterObject(properties) {
                    this.blocks = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ChapterObject name.
                 * @member {string} name
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @instance
                 */
                ChapterObject.prototype.name = "";

                /**
                 * ChapterObject digest.
                 * @member {com.naphaso.bookreader.IDigest|null|undefined} digest
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @instance
                 */
                ChapterObject.prototype.digest = null;

                /**
                 * ChapterObject blocks.
                 * @member {Array.<com.naphaso.bookreader.IBlock>} blocks
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @instance
                 */
                ChapterObject.prototype.blocks = $util.emptyArray;

                /**
                 * Creates a new ChapterObject instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {com.naphaso.bookreader.IChapterObject=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.ChapterObject} ChapterObject instance
                 */
                ChapterObject.create = function create(properties) {
                    return new ChapterObject(properties);
                };

                /**
                 * Encodes the specified ChapterObject message. Does not implicitly {@link com.naphaso.bookreader.ChapterObject.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {com.naphaso.bookreader.IChapterObject} message ChapterObject message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChapterObject.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.digest != null && Object.hasOwnProperty.call(message, "digest"))
                        $root.com.naphaso.bookreader.Digest.encode(message.digest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.blocks != null && message.blocks.length)
                        for (let i = 0; i < message.blocks.length; ++i)
                            $root.com.naphaso.bookreader.Block.encode(message.blocks[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ChapterObject message, length delimited. Does not implicitly {@link com.naphaso.bookreader.ChapterObject.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {com.naphaso.bookreader.IChapterObject} message ChapterObject message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ChapterObject.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ChapterObject message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.ChapterObject} ChapterObject
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChapterObject.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.ChapterObject();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                message.digest = $root.com.naphaso.bookreader.Digest.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.blocks && message.blocks.length))
                                    message.blocks = [];
                                message.blocks.push($root.com.naphaso.bookreader.Block.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ChapterObject message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.ChapterObject} ChapterObject
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ChapterObject.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ChapterObject message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ChapterObject.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.digest != null && message.hasOwnProperty("digest")) {
                        let error = $root.com.naphaso.bookreader.Digest.verify(message.digest);
                        if (error)
                            return "digest." + error;
                    }
                    if (message.blocks != null && message.hasOwnProperty("blocks")) {
                        if (!Array.isArray(message.blocks))
                            return "blocks: array expected";
                        for (let i = 0; i < message.blocks.length; ++i) {
                            let error = $root.com.naphaso.bookreader.Block.verify(message.blocks[i]);
                            if (error)
                                return "blocks." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ChapterObject message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.ChapterObject} ChapterObject
                 */
                ChapterObject.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.ChapterObject)
                        return object;
                    let message = new $root.com.naphaso.bookreader.ChapterObject();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.digest != null) {
                        if (typeof object.digest !== "object")
                            throw TypeError(".com.naphaso.bookreader.ChapterObject.digest: object expected");
                        message.digest = $root.com.naphaso.bookreader.Digest.fromObject(object.digest);
                    }
                    if (object.blocks) {
                        if (!Array.isArray(object.blocks))
                            throw TypeError(".com.naphaso.bookreader.ChapterObject.blocks: array expected");
                        message.blocks = [];
                        for (let i = 0; i < object.blocks.length; ++i) {
                            if (typeof object.blocks[i] !== "object")
                                throw TypeError(".com.naphaso.bookreader.ChapterObject.blocks: object expected");
                            message.blocks[i] = $root.com.naphaso.bookreader.Block.fromObject(object.blocks[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ChapterObject message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {com.naphaso.bookreader.ChapterObject} message ChapterObject
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ChapterObject.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.blocks = [];
                    if (options.defaults) {
                        object.name = "";
                        object.digest = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.digest != null && message.hasOwnProperty("digest"))
                        object.digest = $root.com.naphaso.bookreader.Digest.toObject(message.digest, options);
                    if (message.blocks && message.blocks.length) {
                        object.blocks = [];
                        for (let j = 0; j < message.blocks.length; ++j)
                            object.blocks[j] = $root.com.naphaso.bookreader.Block.toObject(message.blocks[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ChapterObject to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ChapterObject.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ChapterObject
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.ChapterObject
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ChapterObject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.ChapterObject";
                };

                return ChapterObject;
            })();

            bookreader.Block = (function() {

                /**
                 * Properties of a Block.
                 * @memberof com.naphaso.bookreader
                 * @interface IBlock
                 * @property {com.naphaso.bookreader.IDigest|null} [digest] Block digest
                 * @property {com.naphaso.bookreader.IBlockAttrs|null} [attrs] Block attrs
                 * @property {Array.<com.naphaso.bookreader.ISpan>|null} [spans] Block spans
                 */

                /**
                 * Constructs a new Block.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a Block.
                 * @implements IBlock
                 * @constructor
                 * @param {com.naphaso.bookreader.IBlock=} [properties] Properties to set
                 */
                function Block(properties) {
                    this.spans = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Block digest.
                 * @member {com.naphaso.bookreader.IDigest|null|undefined} digest
                 * @memberof com.naphaso.bookreader.Block
                 * @instance
                 */
                Block.prototype.digest = null;

                /**
                 * Block attrs.
                 * @member {com.naphaso.bookreader.IBlockAttrs|null|undefined} attrs
                 * @memberof com.naphaso.bookreader.Block
                 * @instance
                 */
                Block.prototype.attrs = null;

                /**
                 * Block spans.
                 * @member {Array.<com.naphaso.bookreader.ISpan>} spans
                 * @memberof com.naphaso.bookreader.Block
                 * @instance
                 */
                Block.prototype.spans = $util.emptyArray;

                /**
                 * Creates a new Block instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {com.naphaso.bookreader.IBlock=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.Block} Block instance
                 */
                Block.create = function create(properties) {
                    return new Block(properties);
                };

                /**
                 * Encodes the specified Block message. Does not implicitly {@link com.naphaso.bookreader.Block.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {com.naphaso.bookreader.IBlock} message Block message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Block.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.digest != null && Object.hasOwnProperty.call(message, "digest"))
                        $root.com.naphaso.bookreader.Digest.encode(message.digest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.attrs != null && Object.hasOwnProperty.call(message, "attrs"))
                        $root.com.naphaso.bookreader.BlockAttrs.encode(message.attrs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.spans != null && message.spans.length)
                        for (let i = 0; i < message.spans.length; ++i)
                            $root.com.naphaso.bookreader.Span.encode(message.spans[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Block message, length delimited. Does not implicitly {@link com.naphaso.bookreader.Block.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {com.naphaso.bookreader.IBlock} message Block message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Block.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Block message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.Block} Block
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Block.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.Block();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.digest = $root.com.naphaso.bookreader.Digest.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.attrs = $root.com.naphaso.bookreader.BlockAttrs.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.spans && message.spans.length))
                                    message.spans = [];
                                message.spans.push($root.com.naphaso.bookreader.Span.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Block message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.Block} Block
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Block.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Block message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Block.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.digest != null && message.hasOwnProperty("digest")) {
                        let error = $root.com.naphaso.bookreader.Digest.verify(message.digest);
                        if (error)
                            return "digest." + error;
                    }
                    if (message.attrs != null && message.hasOwnProperty("attrs")) {
                        let error = $root.com.naphaso.bookreader.BlockAttrs.verify(message.attrs);
                        if (error)
                            return "attrs." + error;
                    }
                    if (message.spans != null && message.hasOwnProperty("spans")) {
                        if (!Array.isArray(message.spans))
                            return "spans: array expected";
                        for (let i = 0; i < message.spans.length; ++i) {
                            let error = $root.com.naphaso.bookreader.Span.verify(message.spans[i]);
                            if (error)
                                return "spans." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a Block message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.Block} Block
                 */
                Block.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.Block)
                        return object;
                    let message = new $root.com.naphaso.bookreader.Block();
                    if (object.digest != null) {
                        if (typeof object.digest !== "object")
                            throw TypeError(".com.naphaso.bookreader.Block.digest: object expected");
                        message.digest = $root.com.naphaso.bookreader.Digest.fromObject(object.digest);
                    }
                    if (object.attrs != null) {
                        if (typeof object.attrs !== "object")
                            throw TypeError(".com.naphaso.bookreader.Block.attrs: object expected");
                        message.attrs = $root.com.naphaso.bookreader.BlockAttrs.fromObject(object.attrs);
                    }
                    if (object.spans) {
                        if (!Array.isArray(object.spans))
                            throw TypeError(".com.naphaso.bookreader.Block.spans: array expected");
                        message.spans = [];
                        for (let i = 0; i < object.spans.length; ++i) {
                            if (typeof object.spans[i] !== "object")
                                throw TypeError(".com.naphaso.bookreader.Block.spans: object expected");
                            message.spans[i] = $root.com.naphaso.bookreader.Span.fromObject(object.spans[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Block message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {com.naphaso.bookreader.Block} message Block
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Block.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.spans = [];
                    if (options.defaults) {
                        object.digest = null;
                        object.attrs = null;
                    }
                    if (message.digest != null && message.hasOwnProperty("digest"))
                        object.digest = $root.com.naphaso.bookreader.Digest.toObject(message.digest, options);
                    if (message.attrs != null && message.hasOwnProperty("attrs"))
                        object.attrs = $root.com.naphaso.bookreader.BlockAttrs.toObject(message.attrs, options);
                    if (message.spans && message.spans.length) {
                        object.spans = [];
                        for (let j = 0; j < message.spans.length; ++j)
                            object.spans[j] = $root.com.naphaso.bookreader.Span.toObject(message.spans[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this Block to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.Block
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Block.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Block
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.Block
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Block.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.Block";
                };

                return Block;
            })();

            bookreader.BlockAttrs = (function() {

                /**
                 * Properties of a BlockAttrs.
                 * @memberof com.naphaso.bookreader
                 * @interface IBlockAttrs
                 */

                /**
                 * Constructs a new BlockAttrs.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a BlockAttrs.
                 * @implements IBlockAttrs
                 * @constructor
                 * @param {com.naphaso.bookreader.IBlockAttrs=} [properties] Properties to set
                 */
                function BlockAttrs(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BlockAttrs instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {com.naphaso.bookreader.IBlockAttrs=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.BlockAttrs} BlockAttrs instance
                 */
                BlockAttrs.create = function create(properties) {
                    return new BlockAttrs(properties);
                };

                /**
                 * Encodes the specified BlockAttrs message. Does not implicitly {@link com.naphaso.bookreader.BlockAttrs.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {com.naphaso.bookreader.IBlockAttrs} message BlockAttrs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BlockAttrs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BlockAttrs message, length delimited. Does not implicitly {@link com.naphaso.bookreader.BlockAttrs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {com.naphaso.bookreader.IBlockAttrs} message BlockAttrs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BlockAttrs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BlockAttrs message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.BlockAttrs} BlockAttrs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BlockAttrs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.BlockAttrs();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BlockAttrs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.BlockAttrs} BlockAttrs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BlockAttrs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BlockAttrs message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlockAttrs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BlockAttrs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.BlockAttrs} BlockAttrs
                 */
                BlockAttrs.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.BlockAttrs)
                        return object;
                    return new $root.com.naphaso.bookreader.BlockAttrs();
                };

                /**
                 * Creates a plain object from a BlockAttrs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {com.naphaso.bookreader.BlockAttrs} message BlockAttrs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlockAttrs.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BlockAttrs to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlockAttrs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BlockAttrs
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.BlockAttrs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BlockAttrs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.BlockAttrs";
                };

                return BlockAttrs;
            })();

            bookreader.Span = (function() {

                /**
                 * Properties of a Span.
                 * @memberof com.naphaso.bookreader
                 * @interface ISpan
                 * @property {com.naphaso.bookreader.ISpanAttrs|null} [attrs] Span attrs
                 * @property {com.naphaso.bookreader.IDigest|null} [digest] Span digest
                 * @property {string|null} [text] Span text
                 */

                /**
                 * Constructs a new Span.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a Span.
                 * @implements ISpan
                 * @constructor
                 * @param {com.naphaso.bookreader.ISpan=} [properties] Properties to set
                 */
                function Span(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Span attrs.
                 * @member {com.naphaso.bookreader.ISpanAttrs|null|undefined} attrs
                 * @memberof com.naphaso.bookreader.Span
                 * @instance
                 */
                Span.prototype.attrs = null;

                /**
                 * Span digest.
                 * @member {com.naphaso.bookreader.IDigest|null|undefined} digest
                 * @memberof com.naphaso.bookreader.Span
                 * @instance
                 */
                Span.prototype.digest = null;

                /**
                 * Span text.
                 * @member {string} text
                 * @memberof com.naphaso.bookreader.Span
                 * @instance
                 */
                Span.prototype.text = "";

                /**
                 * Creates a new Span instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {com.naphaso.bookreader.ISpan=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.Span} Span instance
                 */
                Span.create = function create(properties) {
                    return new Span(properties);
                };

                /**
                 * Encodes the specified Span message. Does not implicitly {@link com.naphaso.bookreader.Span.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {com.naphaso.bookreader.ISpan} message Span message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Span.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.attrs != null && Object.hasOwnProperty.call(message, "attrs"))
                        $root.com.naphaso.bookreader.SpanAttrs.encode(message.attrs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.digest != null && Object.hasOwnProperty.call(message, "digest"))
                        $root.com.naphaso.bookreader.Digest.encode(message.digest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
                    return writer;
                };

                /**
                 * Encodes the specified Span message, length delimited. Does not implicitly {@link com.naphaso.bookreader.Span.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {com.naphaso.bookreader.ISpan} message Span message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Span.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Span message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.Span} Span
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Span.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.Span();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.attrs = $root.com.naphaso.bookreader.SpanAttrs.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.digest = $root.com.naphaso.bookreader.Digest.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.text = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Span message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.Span} Span
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Span.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Span message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Span.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attrs != null && message.hasOwnProperty("attrs")) {
                        let error = $root.com.naphaso.bookreader.SpanAttrs.verify(message.attrs);
                        if (error)
                            return "attrs." + error;
                    }
                    if (message.digest != null && message.hasOwnProperty("digest")) {
                        let error = $root.com.naphaso.bookreader.Digest.verify(message.digest);
                        if (error)
                            return "digest." + error;
                    }
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    return null;
                };

                /**
                 * Creates a Span message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.Span} Span
                 */
                Span.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.Span)
                        return object;
                    let message = new $root.com.naphaso.bookreader.Span();
                    if (object.attrs != null) {
                        if (typeof object.attrs !== "object")
                            throw TypeError(".com.naphaso.bookreader.Span.attrs: object expected");
                        message.attrs = $root.com.naphaso.bookreader.SpanAttrs.fromObject(object.attrs);
                    }
                    if (object.digest != null) {
                        if (typeof object.digest !== "object")
                            throw TypeError(".com.naphaso.bookreader.Span.digest: object expected");
                        message.digest = $root.com.naphaso.bookreader.Digest.fromObject(object.digest);
                    }
                    if (object.text != null)
                        message.text = String(object.text);
                    return message;
                };

                /**
                 * Creates a plain object from a Span message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {com.naphaso.bookreader.Span} message Span
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Span.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.attrs = null;
                        object.digest = null;
                        object.text = "";
                    }
                    if (message.attrs != null && message.hasOwnProperty("attrs"))
                        object.attrs = $root.com.naphaso.bookreader.SpanAttrs.toObject(message.attrs, options);
                    if (message.digest != null && message.hasOwnProperty("digest"))
                        object.digest = $root.com.naphaso.bookreader.Digest.toObject(message.digest, options);
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    return object;
                };

                /**
                 * Converts this Span to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.Span
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Span.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Span
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.Span
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Span.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.Span";
                };

                return Span;
            })();

            bookreader.SpanAttrs = (function() {

                /**
                 * Properties of a SpanAttrs.
                 * @memberof com.naphaso.bookreader
                 * @interface ISpanAttrs
                 * @property {boolean|null} [bold] SpanAttrs bold
                 * @property {boolean|null} [italic] SpanAttrs italic
                 * @property {boolean|null} [underline] SpanAttrs underline
                 */

                /**
                 * Constructs a new SpanAttrs.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a SpanAttrs.
                 * @implements ISpanAttrs
                 * @constructor
                 * @param {com.naphaso.bookreader.ISpanAttrs=} [properties] Properties to set
                 */
                function SpanAttrs(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SpanAttrs bold.
                 * @member {boolean} bold
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @instance
                 */
                SpanAttrs.prototype.bold = false;

                /**
                 * SpanAttrs italic.
                 * @member {boolean} italic
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @instance
                 */
                SpanAttrs.prototype.italic = false;

                /**
                 * SpanAttrs underline.
                 * @member {boolean} underline
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @instance
                 */
                SpanAttrs.prototype.underline = false;

                /**
                 * Creates a new SpanAttrs instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {com.naphaso.bookreader.ISpanAttrs=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.SpanAttrs} SpanAttrs instance
                 */
                SpanAttrs.create = function create(properties) {
                    return new SpanAttrs(properties);
                };

                /**
                 * Encodes the specified SpanAttrs message. Does not implicitly {@link com.naphaso.bookreader.SpanAttrs.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {com.naphaso.bookreader.ISpanAttrs} message SpanAttrs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpanAttrs.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bold != null && Object.hasOwnProperty.call(message, "bold"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.bold);
                    if (message.italic != null && Object.hasOwnProperty.call(message, "italic"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.italic);
                    if (message.underline != null && Object.hasOwnProperty.call(message, "underline"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.underline);
                    return writer;
                };

                /**
                 * Encodes the specified SpanAttrs message, length delimited. Does not implicitly {@link com.naphaso.bookreader.SpanAttrs.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {com.naphaso.bookreader.ISpanAttrs} message SpanAttrs message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpanAttrs.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SpanAttrs message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.SpanAttrs} SpanAttrs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpanAttrs.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.SpanAttrs();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.bold = reader.bool();
                                break;
                            }
                        case 2: {
                                message.italic = reader.bool();
                                break;
                            }
                        case 3: {
                                message.underline = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SpanAttrs message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.SpanAttrs} SpanAttrs
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpanAttrs.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SpanAttrs message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpanAttrs.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bold != null && message.hasOwnProperty("bold"))
                        if (typeof message.bold !== "boolean")
                            return "bold: boolean expected";
                    if (message.italic != null && message.hasOwnProperty("italic"))
                        if (typeof message.italic !== "boolean")
                            return "italic: boolean expected";
                    if (message.underline != null && message.hasOwnProperty("underline"))
                        if (typeof message.underline !== "boolean")
                            return "underline: boolean expected";
                    return null;
                };

                /**
                 * Creates a SpanAttrs message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.SpanAttrs} SpanAttrs
                 */
                SpanAttrs.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.SpanAttrs)
                        return object;
                    let message = new $root.com.naphaso.bookreader.SpanAttrs();
                    if (object.bold != null)
                        message.bold = Boolean(object.bold);
                    if (object.italic != null)
                        message.italic = Boolean(object.italic);
                    if (object.underline != null)
                        message.underline = Boolean(object.underline);
                    return message;
                };

                /**
                 * Creates a plain object from a SpanAttrs message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {com.naphaso.bookreader.SpanAttrs} message SpanAttrs
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpanAttrs.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.bold = false;
                        object.italic = false;
                        object.underline = false;
                    }
                    if (message.bold != null && message.hasOwnProperty("bold"))
                        object.bold = message.bold;
                    if (message.italic != null && message.hasOwnProperty("italic"))
                        object.italic = message.italic;
                    if (message.underline != null && message.hasOwnProperty("underline"))
                        object.underline = message.underline;
                    return object;
                };

                /**
                 * Converts this SpanAttrs to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpanAttrs.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SpanAttrs
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.SpanAttrs
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpanAttrs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.SpanAttrs";
                };

                return SpanAttrs;
            })();

            bookreader.Digest = (function() {

                /**
                 * Properties of a Digest.
                 * @memberof com.naphaso.bookreader
                 * @interface IDigest
                 * @property {string|null} [hash] Digest hash
                 * @property {number|Long|null} [words] Digest words
                 * @property {number|Long|null} [characters] Digest characters
                 */

                /**
                 * Constructs a new Digest.
                 * @memberof com.naphaso.bookreader
                 * @classdesc Represents a Digest.
                 * @implements IDigest
                 * @constructor
                 * @param {com.naphaso.bookreader.IDigest=} [properties] Properties to set
                 */
                function Digest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Digest hash.
                 * @member {string} hash
                 * @memberof com.naphaso.bookreader.Digest
                 * @instance
                 */
                Digest.prototype.hash = "";

                /**
                 * Digest words.
                 * @member {number|Long} words
                 * @memberof com.naphaso.bookreader.Digest
                 * @instance
                 */
                Digest.prototype.words = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Digest characters.
                 * @member {number|Long} characters
                 * @memberof com.naphaso.bookreader.Digest
                 * @instance
                 */
                Digest.prototype.characters = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Digest instance using the specified properties.
                 * @function create
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {com.naphaso.bookreader.IDigest=} [properties] Properties to set
                 * @returns {com.naphaso.bookreader.Digest} Digest instance
                 */
                Digest.create = function create(properties) {
                    return new Digest(properties);
                };

                /**
                 * Encodes the specified Digest message. Does not implicitly {@link com.naphaso.bookreader.Digest.verify|verify} messages.
                 * @function encode
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {com.naphaso.bookreader.IDigest} message Digest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Digest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
                    if (message.words != null && Object.hasOwnProperty.call(message, "words"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.words);
                    if (message.characters != null && Object.hasOwnProperty.call(message, "characters"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.characters);
                    return writer;
                };

                /**
                 * Encodes the specified Digest message, length delimited. Does not implicitly {@link com.naphaso.bookreader.Digest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {com.naphaso.bookreader.IDigest} message Digest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Digest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Digest message from the specified reader or buffer.
                 * @function decode
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {com.naphaso.bookreader.Digest} Digest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Digest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.naphaso.bookreader.Digest();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hash = reader.string();
                                break;
                            }
                        case 2: {
                                message.words = reader.int64();
                                break;
                            }
                        case 3: {
                                message.characters = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Digest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {com.naphaso.bookreader.Digest} Digest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Digest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Digest message.
                 * @function verify
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Digest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hash != null && message.hasOwnProperty("hash"))
                        if (!$util.isString(message.hash))
                            return "hash: string expected";
                    if (message.words != null && message.hasOwnProperty("words"))
                        if (!$util.isInteger(message.words) && !(message.words && $util.isInteger(message.words.low) && $util.isInteger(message.words.high)))
                            return "words: integer|Long expected";
                    if (message.characters != null && message.hasOwnProperty("characters"))
                        if (!$util.isInteger(message.characters) && !(message.characters && $util.isInteger(message.characters.low) && $util.isInteger(message.characters.high)))
                            return "characters: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Digest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {com.naphaso.bookreader.Digest} Digest
                 */
                Digest.fromObject = function fromObject(object) {
                    if (object instanceof $root.com.naphaso.bookreader.Digest)
                        return object;
                    let message = new $root.com.naphaso.bookreader.Digest();
                    if (object.hash != null)
                        message.hash = String(object.hash);
                    if (object.words != null)
                        if ($util.Long)
                            (message.words = $util.Long.fromValue(object.words)).unsigned = false;
                        else if (typeof object.words === "string")
                            message.words = parseInt(object.words, 10);
                        else if (typeof object.words === "number")
                            message.words = object.words;
                        else if (typeof object.words === "object")
                            message.words = new $util.LongBits(object.words.low >>> 0, object.words.high >>> 0).toNumber();
                    if (object.characters != null)
                        if ($util.Long)
                            (message.characters = $util.Long.fromValue(object.characters)).unsigned = false;
                        else if (typeof object.characters === "string")
                            message.characters = parseInt(object.characters, 10);
                        else if (typeof object.characters === "number")
                            message.characters = object.characters;
                        else if (typeof object.characters === "object")
                            message.characters = new $util.LongBits(object.characters.low >>> 0, object.characters.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Digest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {com.naphaso.bookreader.Digest} message Digest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Digest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.hash = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.words = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.words = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.characters = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.characters = options.longs === String ? "0" : 0;
                    }
                    if (message.hash != null && message.hasOwnProperty("hash"))
                        object.hash = message.hash;
                    if (message.words != null && message.hasOwnProperty("words"))
                        if (typeof message.words === "number")
                            object.words = options.longs === String ? String(message.words) : message.words;
                        else
                            object.words = options.longs === String ? $util.Long.prototype.toString.call(message.words) : options.longs === Number ? new $util.LongBits(message.words.low >>> 0, message.words.high >>> 0).toNumber() : message.words;
                    if (message.characters != null && message.hasOwnProperty("characters"))
                        if (typeof message.characters === "number")
                            object.characters = options.longs === String ? String(message.characters) : message.characters;
                        else
                            object.characters = options.longs === String ? $util.Long.prototype.toString.call(message.characters) : options.longs === Number ? new $util.LongBits(message.characters.low >>> 0, message.characters.high >>> 0).toNumber() : message.characters;
                    return object;
                };

                /**
                 * Converts this Digest to JSON.
                 * @function toJSON
                 * @memberof com.naphaso.bookreader.Digest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Digest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Digest
                 * @function getTypeUrl
                 * @memberof com.naphaso.bookreader.Digest
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Digest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/com.naphaso.bookreader.Digest";
                };

                return Digest;
            })();

            return bookreader;
        })();

        return naphaso;
    })();

    return com;
})();

export { $root as default };
